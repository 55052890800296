<template>
  <svg
    :height="height"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Automox Logo"
    fill="none"
    role="img"
    viewBox="0 0 30 27"
  >
    <title>Automox Logo</title>
    <mask id="path-1-inside-1" :fill="fill">
      <path
        d="M0 22.7573L12.8751 0L17.1249 0L30 22.7573L27.7314 27H2.54123L0 22.7573Z"
      />
    </mask>
    <!-- eslint-disable max-len -->
    <path
      d="M6.49423 19.1128L6.05727 18.8698L5.64399 19.6128H6.49423V19.1128ZM16.8538 7.26121L17.2917 7.01988L16.8396 6.19935L16.4096 7.03174L16.8538 7.26121ZM21.9246 22.7573V23.2573H22.7952L22.3564 22.5054L21.9246 22.7573ZM16.6879 -0.243038L6.05727 18.8698L6.93119 19.3559L17.5618 0.243038L16.6879 -0.243038ZM20.2305 18.8617L15.4324 10.599L14.5676 11.1012L19.3657 19.3639L20.2305 18.8617ZM6.49423 19.6128H10.3382V18.6128H6.49423V19.6128ZM10.3382 19.6128H19.7981V18.6128H10.3382V19.6128ZM14.5645 10.6044L9.90271 18.8671L10.7737 19.3585L15.4355 11.0958L14.5645 10.6044ZM15.4442 11.0795L17.2981 7.49067L16.4096 7.03174L14.5558 10.6206L15.4442 11.0795ZM16.4159 7.50253L27.2935 27.2413L28.1693 26.7587L17.2917 7.01988L16.4159 7.50253ZM19.3663 19.3648L21.4927 23.0093L22.3564 22.5054L20.23 18.8609L19.3663 19.3648ZM21.9246 22.2573H0L0 23.2573H21.9246V22.2573ZM17.1249 0L17.9952 -0.492413L17.7081 -1H17.1249V0ZM27.7314 27V28H28.3307L28.6132 27.4715L27.7314 27ZM0 22.7573L-0.870361 22.2649L-1.15707 22.7717L-0.857882 23.2712L0 22.7573ZM12.8751 0V-1H12.2919L12.0048 -0.492413L12.8751 0ZM2.54123 27L1.68335 27.5138L1.97454 28H2.54123L2.54123 27ZM30 22.7573L30.8818 23.2289L31.1413 22.7437L30.8704 22.2649L30 22.7573ZM0.870361 23.2498L13.7455 0.492413L12.0048 -0.492413L-0.870361 22.2649L0.870361 23.2498ZM17.1249 -1L12.8751 -1V1L17.1249 1V-1ZM27.7314 26H2.54123L2.54123 28H27.7314V26ZM-0.857882 23.2712L1.68335 27.5138L3.39911 26.4862L0.857882 22.2435L-0.857882 23.2712ZM16.2545 0.492413L29.1296 23.2498L30.8704 22.2649L17.9952 -0.492413L16.2545 0.492413ZM28.6132 27.4715L30.8818 23.2289L29.1182 22.2858L26.8495 26.5285L28.6132 27.4715Z"
      :fill="fill"
      mask="url(#path-1-inside-1)"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconAutomox',
  props: {
    height: {
      type: [Number, String],
      default: 30,
    },
    width: {
      type: [Number, String],
      default: 27,
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>