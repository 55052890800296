import { render, staticRenderFns } from "./CopyClipboard.vue?vue&type=template&id=5af3c1ca&scoped=true&"
import script from "./CopyClipboard.vue?vue&type=script&lang=ts&"
export * from "./CopyClipboard.vue?vue&type=script&lang=ts&"
import style0 from "./CopyClipboard.vue?vue&type=style&index=0&id=5af3c1ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5af3c1ca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VIcon,VProgressCircular})
